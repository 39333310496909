<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item
          label="维度状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.group" v-decorator="['group']" placeholder="标签分组">-->
        <!--        </a-input>-->
        <a-radio-group v-decorator="['status',{initialValue: 1}]" v-model="form.status">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
          label="分类角色"
          prop="dimType"
          v-bind="formItemLayout"
      >
        <a-radio-group v-decorator="['dimType',{initialValue: 1}]" v-model="form.dimType">
          <a-radio :value="1">默认</a-radio>
          <a-radio :value="2">员工</a-radio>
          <a-radio :value="3">合作商</a-radio>
          <a-radio :value="4">客户</a-radio>
          <a-radio :value= "5">同行</a-radio>
        </a-radio-group>
        <div style="color:#ff9900;margin:-10px 0;">【角色+维度名称】必须唯一！【角色+字段名称】必须唯一！</div>
      </a-form-model-item>
      <a-form-model-item label="维度名称" v-bind="formItemLayout">
        <a-input v-model="form.dimName" v-decorator="['dimName']" placeholder="公司维度名称，最多6个字" :max-length="6">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="字段名称" v-bind="formItemLayout">
        <a-select v-model="form.dimField" v-decorator="['dimField']">
          <a-select-option v-for="r in 15" :key="'rate'+r">rate{{ r }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="维度说明" v-bind="formItemLayout">
        <a-textarea v-model="form.dimDesc" v-decorator="['dimDesc']" placeholder="描述说明" style="height:200px;">
        </a-textarea>
      </a-form-model-item>
      <a-form-model-item label="排序序号" v-bind="formItemLayout">
        <a-input-number v-model="form.sortNumber" v-decorator="['sortNumber']" placeholder="排序序号，越大越优先">
        </a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifyDimensionModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 18},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        dimName: [
          {
            required: true,
            message: "维度名称不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改公司维度：" + record.dimName;
        this.form = {
          ...record,
        };
      } else {
        this.title = "新增公司维度"
        this.form = {}
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("dimension/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          }).catch(()=>{
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
